.nav-list {
  list-style-type: none; /* Remove bullets */
  padding: 0;
  margin: 0;
  display: flex; /* This will align the list items horizontally */
}

.nav-list li {
  margin-right: 20px; /* Adjust the space between items */
}

.nav-list li:last-child {
  margin-right: 0; /* Remove margin from the last item */
}
