.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    color: #333;
    margin-top: 20px;
  }
  
  ul {
    margin-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  footer {
    text-align: center;
    font-size: 14px;
    margin-top: 40px;
    color: #777;
  }
  
  a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  