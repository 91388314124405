.container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  section {
    margin-bottom: 15px;
  }
  
  h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  
  ul {
    margin-left: 20px;
  }
  